define("discourse/plugins/discourse-gamification/discourse/templates/admin-plugins/show/discourse-gamification-leaderboards/show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-detail">
    <AdminEditLeaderboard @leaderboard={{@model}} />
  </div>
  */
  {
    "id": "EmMeGVdT",
    "block": "[[[10,0],[14,0,\"admin-detail\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@leaderboard\"],[[30,1]]],null],[1,\"\\n\"],[13]],[\"@model\"],false,[\"admin-edit-leaderboard\"]]",
    "moduleName": "discourse/plugins/discourse-gamification/discourse/templates/admin-plugins/show/discourse-gamification-leaderboards/show.hbs",
    "isStrictMode": false
  });
});